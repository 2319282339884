//global
import React, { Ref, useContext, useEffect, useRef, useState } from 'react';
import { Field, useSitecoreContext, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { Form as FormikForm, Formik, FormikProps, FormikHelpers } from 'formik';
import _ from 'lodash';
//lib
import ComponentContext from 'lib/context/ComponentContext';
//local
import { ComponentProps } from 'lib/component-props';
import { FormFieldsProps, transformData } from 'src/utils/formUtils';
import TextHelper from 'src/helpers/commonComponents/TextHelper';
import TextField from 'src/helpers/Form/TextField';
import PhoneField, { formatPhoneForDisplay } from 'src/helpers/Form/PhoneField';
//redux
import useDictionary from 'src/hooks/useDictionary';
import { Me, MeUser } from 'ordercloud-javascript-sdk';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import { updateUser } from 'src/redux/ocUser';
import Loader from 'components/Loader/Loader';
import { formatPhoneForSaving } from 'lib/utils/string-utils';
import { myAccountDetailsTailwindVariant } from 'tailwindVariants/components/myAccountDetailsTailwindVariant';
//type
export type MyAccountDetailsProps = ComponentProps & {
  fields: {
    data: {
      data: {
        title: Field<string>;
        shortDescription: Field<string>;
        submitButtonText: Field<string>;
        cancelButtonText?: Field<string>;
        failureMessage?: Field<string>;
        successMessage?: Field<string>;
        errors?: {
          values: { name: string; value: string }[];
        };
        formFields: {
          targetItems: Array<FormFieldsProps>;
        };
      };
      city?: {
        regions: {
          targetItems: State[];
        };
      };
    };
  };
};
interface State {
  code: { value: string };
  name: { value: string };
}

interface FormValuesTypes {
  Email: string;
  HomeNumber?: string;
  CellNumber: string;
  FirstName: string;
  LastName: string;
}

/**chacking the initial values if all values are empty or not */
//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const allValuesAreEmpty = (obj: any): boolean => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== '') {
      return false;
    }
  }
  return true;
};
//main component
const MyAccountDetails: React.FC<MyAccountDetailsProps> = ({ fields, params }) => {
  const {
    base,
    headigWrapper,
    textLabel,
    textLabelBold,
    smallLabel,
    labelBold,
    content,
    editingContent,
    inlineFields,
    informationContainer,
    fieldWrapper,
    userInfo,
    linkText,
    submitBtn,
    cancelBtn,
    loaderWrapper,
    textLabelMedium,
    pageTitle,
  } = myAccountDetailsTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  //-----------------------------------

  const userDetails = useOcSelector((state) => state?.ocUser);

  const initialValues: FormValuesTypes = {
    FirstName: userDetails?.user?.FirstName ?? '',
    LastName: userDetails?.user?.LastName ?? '',
    Email: userDetails?.user?.Email ?? '',
    HomeNumber: formatPhoneForDisplay(userDetails?.user?.xp?.HomeNumber),
    CellNumber: formatPhoneForDisplay(userDetails?.user?.Phone),
  };
  const [loading, setIsLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const [fieldValues, setFieldValues] = useState<FormValuesTypes>();
  const [formInitialValues, setFormInitialValues] = useState<FormValuesTypes>(initialValues);
  const activeSection = fields?.data?.data?.title?.value;
  const formRef = useRef<FormikHelpers<FormValuesTypes>>(null);
  const { getDictionaryValue } = useDictionary();
  const dispatch = useOcDispatch();
  const transFormFields = transformData(fields?.data?.data?.formFields);
  useEffect(() => {
    const isEqual = _.isEqual(formInitialValues, fieldValues);
    !isEqual && setcomponentContextData({ ...componentContextData, isEqualFields: false });
  }, [fieldValues]);

  //handling accordinan open and close animation
  useEffect(() => {
    const item = document?.querySelectorAll('.myAccountDetails');
    item.forEach((item) => {
      const content = item.querySelector<HTMLElement>('.accountContent');
      const form = item.querySelector<HTMLElement>('.accountEditContent');
      if (item.classList.contains('open')) {
        if (componentContextData?.collapse === fields?.data?.data?.title?.value) {
          form ? (form.style.maxHeight = '1000px') : '';
          content ? (content.style.maxHeight = '0px') : '';
        } else {
          form ? (form.style.maxHeight = `0px`) : '';
          content ? (content.style.maxHeight = `${200}px`) : '';
        }
      }
    });
  }, [componentContextData?.collapse]);

  const getUserDetails = async () => {
    setIsLoading(true);
    const userdetails: MeUser = await Me.Get();
    setFormInitialValues({
      FirstName: userdetails?.FirstName ?? '',
      LastName: userdetails?.LastName ?? '',
      CellNumber: formatPhoneForDisplay(userdetails?.Phone),
      HomeNumber: formatPhoneForDisplay(userdetails?.xp?.HomePhone),
      Email: userdetails?.Email ?? '',
    });
    setIsLoading(false);
  };
  //set form initail values
  useEffect(() => {
    if (!userDetails?.loading) getUserDetails();
  }, [userDetails?.loading]);

  //handling the values on onChange
  const setValues = (values: FormValuesTypes) => {
    setFieldValues({
      ...values,
    });
  };

  const siteContext = useSitecoreContext();
  const title: Field<string> = siteContext?.sitecoreContext?.route?.fields
    ?.heading as Field<string>;
  //handling collapse action
  const handleCollapse = (title: string) => {
    setcomponentContextData({
      ...componentContextData,
      isEqualFields: true,
    });
    componentContextData?.isEqualFields
      ? setcomponentContextData({ ...componentContextData, collapse: title, isEqualFields: true })
      : setcomponentContextData({ ...componentContextData, collapse: title, isEqualFields: false });
  };
  //handling submit action
  const submitFormData = async (values: FormValuesTypes) => {
    try {
      setSaveLoading(true);
      const ocUser = {
        ID: userDetails?.user?.ID,
        FirstName: values?.FirstName,
        LastName: values?.LastName,
        Email: values?.Email,
        Phone: formatPhoneForSaving(values?.CellNumber),
        xp: { HomePhone: formatPhoneForSaving(values?.HomeNumber) },
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res = await dispatch(updateUser(ocUser as any));
      if (res) {
        getUserDetails();
        setSaveLoading(false);
        setcomponentContextData({ ...componentContextData, isEqualFields: true, collapse: null });
      }
    } catch (error) {
      console.error('@UserUpdateError: ', error);
    }
  };
  //if no data then returned empty fragment
  // if (fields === undefined || fields === null) return <></>;
  return (
    <>
      <span className={pageTitle()}>{title?.value}</span>
      <div className={base({ className: params?.Style + ' open' ?? '' })}>
        <div className={content({ className: 'accountContent' })}>
          <div className={headigWrapper()}>
            <TextHelper tag="p" field={fields?.data?.data?.title} className={textLabelMedium()} />
            <button
              aria-label="submit"
              aria-controls="button"
              className={linkText()}
              type="submit"
              onClick={() => {
                formRef?.current?.resetForm();
                handleCollapse(activeSection);
                !componentContextData?.isEqualFields &&
                  setcomponentContextData({
                    ...componentContextData,
                    isWarningPopUp: true,
                    currentSection: activeSection,
                  });
                // setcomponentContextData({
                //   ...componentContextData,
                //   currentSection: activeSection,
                //   passwordChangeSuccess: false,
                // });
              }}
            >
              {getDictionaryValue('EditCTA')}
            </button>
          </div>
          {!saveLoading && !loading && initialValues && (
            <div className={userInfo()}>
              <p className={textLabel()}>
                {formInitialValues?.FirstName}&nbsp;{formInitialValues?.LastName}
              </p>
              <p className={textLabel()}>{formInitialValues?.Email}</p>
              {formInitialValues?.HomeNumber && (
                <p className={textLabel()}>
                  {getDictionaryValue('HomeLabel')}
                  {formInitialValues?.HomeNumber}
                </p>
              )}
              {formInitialValues?.CellNumber && (
                <p className={textLabel()}>
                  {getDictionaryValue('CellLabel')}
                  {formInitialValues?.CellNumber}
                </p>
              )}
            </div>
          )}
        </div>
        <div
          className={editingContent({
            className: `accountEditContent ${
              componentContextData?.collapse === fields?.data?.data?.title?.value ? '' : '!py-0'
            }`,
          })}
        >
          <TextHelper tag="p" field={fields?.data?.data?.title} className={textLabelBold()} />
          <TextHelper
            tag="p"
            field={fields?.data?.data?.shortDescription}
            className={smallLabel()}
          />
          {componentContextData?.passwordChangeSuccess === true && (
            <span className={labelBold({ className: 'text-color-brand-primary-1-base' })}>
              {componentContextData?.passwordSuccessMessage}
            </span>
          )}
          {!loading && (
            <Formik
              innerRef={formRef as Ref<FormikProps<FormValuesTypes>>}
              initialValues={formInitialValues}
              onSubmit={(values: FormValuesTypes) => {
                submitFormData(values);
              }}
            >
              {({ resetForm }) => (
                <FormikForm className={informationContainer()}>
                  <div className={inlineFields()}>
                    {transFormFields?.FirstName && (
                      <div className={fieldWrapper()}>
                        <TextField {...transFormFields?.FirstName} setFieldValue={setValues} />
                      </div>
                    )}
                    {transFormFields?.LastName && (
                      <div className={fieldWrapper()}>
                        <TextField {...transFormFields?.LastName} setFieldValue={setValues} />
                      </div>
                    )}
                  </div>
                  {transFormFields?.Email && (
                    <div className={fieldWrapper()}>
                      {/* <TextField {...transFormFields?.Email} /> */}
                      <label className={textLabelBold()}>
                        {transFormFields?.Email?.required ? '*' : ''}
                        {transFormFields?.Email?.label}
                      </label>
                      <p>{initialValues?.Email}</p>
                    </div>
                  )}

                  <button
                    aria-label="Change Password"
                    className={labelBold({
                      className: 'text-color-brand-primary-1-base text-left w-fit',
                    })}
                    onClick={(e) => {
                      e.preventDefault();
                      setcomponentContextData({
                        ...componentContextData,
                        isChangePasswordOpen: true,
                      });
                    }}
                  >
                    {getDictionaryValue('ChangePasswordCTA')}
                  </button>

                  {/**TODO: nedd to addd change password pop-up */}
                  <div className={inlineFields()}>
                    {/* *TODO: update home number */}
                    {transFormFields?.HomeNumber && (
                      <div className={fieldWrapper()}>
                        <PhoneField {...transFormFields?.HomeNumber} setFieldValues={setValues} />
                      </div>
                    )}
                    {transFormFields?.CellNumber && (
                      <div className={fieldWrapper()}>
                        <PhoneField {...transFormFields?.CellNumber} setFieldValues={setValues} />
                      </div>
                    )}
                  </div>

                  <div className={inlineFields()}>
                    {saveLoading ? (
                      <div className={loaderWrapper()}>
                        <Loader />
                        {getDictionaryValue('Saving')}
                      </div>
                    ) : (
                      <>
                        {fields.data?.data?.submitButtonText?.value && (
                          <button aria-label="submit" className={submitBtn()} type="submit">
                            <TextHelper field={fields.data?.data?.submitButtonText} />
                          </button>
                        )}
                        {fields.data?.data?.cancelButtonText?.value && (
                          <button
                            aria-label="reset"
                            type="reset"
                            className={cancelBtn()}
                            onClick={() => {
                              resetForm;
                              setFieldValues(initialValues);
                              setcomponentContextData({
                                ...componentContextData,
                                collapse: null,
                                isEqualFields: true,
                                passwordChangeSuccess: false,
                              });
                            }}
                          >
                            <TextHelper field={fields.data?.data?.cancelButtonText} />
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </FormikForm>
              )}
            </Formik>
          )}
        </div>
      </div>
    </>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<MyAccountDetailsProps>(MyAccountDetails);
