//global
import React, { useEffect, useState } from 'react';
import {
  ComponentRendering,
  Field,
  NextImage,
  Placeholder,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import * as yup from 'yup';

//lib
import { ComponentProps } from 'lib/component-props';
import { PSP } from 'models/PetSuppliesPlus.Model';
import TextHelper from 'src/helpers/commonComponents/TextHelper';
import ButtonHelper from 'src/helpers/commonComponents/Button';
import { apiRequest } from 'src/utils/apiWrapper';
import { useFormik } from 'formik';
import { REQUEST_METHOD } from 'src/helpers/MyPetsHelper';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import { Gender, Species } from 'src/redux/myPetsSlice';
import { updateUser } from 'src/redux/ocUser';
import Loader from 'components/Loader/Loader';
import { petsApi } from 'src/utils/nextApiConfig';
import useDictionary from 'src/hooks/useDictionary';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import ModalWrapper from 'src/helpers/commonComponents/ModalWrapper';
import dynamic from 'next/dynamic';
import { myPetTailwindVariant } from 'tailwindVariants/components/myPetTailwindVariant';
import { removePetPopUpTailwindVariant } from 'tailwindVariants/components/removePetPopUpTailwindVariant';
import { convertToBase64 } from 'src/helpers/convertToBase64';

const DatePicker = dynamic(() => import('react-datepicker')); //Dynamically import react datepicker library for performance improvement.
//type
//breed type
export type Breed = {
  BreedId: string;
  BreedName: string;
  SpeciesId: number;
  MyTimeBreedId: number;
  image?: string;
}[];

//species type

//pet response
export type PetResponse =
  | {
      Birthdate: string;
      BirthdateString: string;
      BreedId: number;
      BreedName: string;
      Gender?: string;
      GenderId: number;
      GenderName: string | null;
      HttpStatusCode: number;
      IsProfileImage: boolean;
      Message: string;
      PetId: number;
      PetImage: string;
      Image?: string;
      PetImageName: string;
      petImage?: string;
      Petname: string;
      Name: string;
      Ppc: string;
      SpeciesId: number;
      SpeciesName: string;
      myTimeChildId?: string;
      ChildName: string; //Data from my time
      Newfiedoodle: string; //Data from my time;
      SaveToProfile?: boolean;
      breedname?: string;
    }
  | undefined;
//myPets
export type MyPetProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.PetProfile.PetInfo.Fields.PetInformationText;
//Add and Edit form
export type AddPet = Omit<MyPetProps, 'rendering' | 'params'> & {
  showAddPetsForm?: boolean;
  setShowAddPetsForm?: React.Dispatch<React.SetStateAction<boolean>>;
  setshowPetsScreen?: React.Dispatch<React.SetStateAction<boolean>>;
  genderList?: Gender;
  petId?: number | string | undefined;
  getAllPets?: () => Promise<void>;
  petDetails?: PetResponse;
  removePet?: (petId: number | string) => void;
  isSaveModal?: boolean;
  setIsSaveModal?: React.Dispatch<React.SetStateAction<boolean>>;
  isRemoveModal?: boolean;
  setIsRemoveModal?: React.Dispatch<React.SetStateAction<boolean>>;
  isEdit?: boolean;
  setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  currentEditPetId?: string | number;
  setCurrentEditPetId?: React.Dispatch<React.SetStateAction<string | number | undefined>>;
  petLoader?: boolean;
  setPetLoader?: React.Dispatch<React.SetStateAction<boolean>>;
  currentPetId?: boolean;
  setCurrentPetId?: React.Dispatch<React.SetStateAction<string | number | undefined>>;
  petGenders?: Gender;
  petSpecies?: Species;
  setIsSetPet?: React.Dispatch<React.SetStateAction<boolean>>;
  geteditformdata?: (data: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedpetitem?: (data: PetResponse, donotupdate?: boolean, matchingBreed?: any) => void;
  onPetImageUpdate?: (newImage: string) => void;
  companyID?: string;
};

//formatingDate
const formatDate = (date: Date) => {
  const d = new Date(date);
  const month = '' + (d.getMonth() + 1);
  const day = '' + d.getDate();
  const year = d.getFullYear();
  return [month.length < 2 ? '0' + month : month, day.length < 2 ? '0' + day : day, year].join('/');
};
//main component
const MyPet: React.FC<MyPetProps> = ({ fields, params, rendering }) => {
  const { base, leftInnerDiv, petListWrapper, loaderWrapper, headingWrapper, heading, addLabel } =
    myPetTailwindVariant({
      size: { initial: 'mobile', lg: 'desktop' },
    });
  //fetching initial data
  const petGenders = useOcSelector((state) => state?.myPetReducer?.gender);
  const petSpecies = useOcSelector((state) => state?.myPetReducer?.species);
  //state
  const [loading, setLoading] = useState(false);
  const [petLoader, setPetLoader] = useState(false);
  const [currentPetId, setCurrentPetId] = useState<string | number>();
  const [showAddPetsForm, setShowAddPetsForm] = useState<boolean>(false);
  const [petList, setPetList] = useState<PetResponse[]>();
  const [isEdit, setIsEdit] = useState(false);
  const [currentUserID, setCurrentUserId] = useState<string>('');
  const [currentEditPetId, setCurrentEditPetId] = useState<string | number>();
  const [isRemoveModal, setIsRemoveModal] = useState(false);
  const isAnonymous = useOcSelector((s) => s.ocAuth.isAnonymous);
  const userDetails = useOcSelector((state) => state?.ocUser);

  const dispatch = useOcDispatch();

  const REQUEST_METHOD = {
    get: 'GET',
    post: 'POST',
    delete: 'DELETE',
  };

  //fetching the current user
  useEffect(() => {
    if (!isAnonymous && !currentUserID && userDetails?.user?.xp?.PPC) {
      setCurrentUserId(userDetails?.user?.xp?.PPC);
    }
    if (userDetails?.user?.xp?.PPC && !userDetails?.loading && !currentUserID) {
      getAllPets();
    }
  }, [userDetails]);

  //adding the species into current user xp values
  type UniquePets = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
  useEffect(() => {
    const updateUserXps = async () => {
      const uniquePets = {} as UniquePets;
      petList &&
        petList?.forEach((pet) => {
          if (!uniquePets.hasOwnProperty(pet?.SpeciesName || '')) {
            uniquePets[pet?.SpeciesName || ''] = new Set();
          }
          uniquePets[pet?.SpeciesName || '']?.add(pet?.Petname);
        });

      // Convert Set to Array
      for (const species in uniquePets) {
        uniquePets[species] = Array.from(uniquePets[species]);
      }
      const ocUserPetsXps = {
        ID: currentUserID,
        xp: {
          Pets: uniquePets,
        },
      };
      const ocUserPetsEmptyXps = {
        ID: currentUserID,
        xp: {
          Pets: '',
        },
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await dispatch(updateUser(ocUserPetsEmptyXps as any));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await dispatch(updateUser(ocUserPetsXps as any));
    };
    updateUserXps();
  }, [petList]);

  //fetching the all pets
  const getAllPets = async () => {
    document?.body?.classList?.contains('overflow-hidden') &&
      document.body.classList.remove('overflow-hidden');
    setPetLoader(true);

    try {
      const options = {
        method: REQUEST_METHOD?.post,
      };
      const res: PetResponse[] = await apiRequest(petsApi?.allPets, options);
      setPetList(res);
    } catch (err) {
      console.error('@@Error', err);
    }
    setPetLoader(false);
  };
  showAddPetsForm && window.scrollTo({ top: 0, behavior: 'smooth' });

  //removing pet
  const removePet = async (petId: number | string) => {
    setLoading(true);
    try {
      const options = {
        method: REQUEST_METHOD?.post,
        data: {
          petId: petId,
        },
      };
      await apiRequest(petsApi?.deletePets, options);
    } catch (err) {
      console.error('@@Error', err);
    }
    setLoading(false);
  };
  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  return (
    <div className={base({ className: params?.Style ?? '' })} id="myPets">
      <div className={leftInnerDiv()}>
        <div className={headingWrapper()}>
          <RichTextHelper field={fields?.heading} className={heading()} />
          <TextHelper
            tag="button"
            className={addLabel({ className: 'underline' })}
            field={fields?.addPetLinkText}
            onClickHandler={(e) => {
              e?.preventDefault(), setShowAddPetsForm(true);
            }}
          />
        </div>
        <RemovePetPopUp
          fields={fields}
          removePet={removePet}
          isRemoveModal={isRemoveModal}
          setIsRemoveModal={setIsRemoveModal}
          petId={currentPetId}
          getAllPets={getAllPets}
        />
        {petLoader ? (
          <div className={loaderWrapper({ className: 'justify-center h-[180px]' })}>
            <Loader />
            <TextHelper field={fields?.petsLoadingText} />
          </div>
        ) : showAddPetsForm ? (
          !loading && (
            <AddPetsForm
              fields={fields}
              setShowAddPetsForm={setShowAddPetsForm}
              getAllPets={getAllPets}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              petGenders={petGenders}
              petSpecies={petSpecies}
              petDetails={petList as PetResponse}
              currentEditPetId={currentEditPetId}
            />
          )
        ) : (
          <div className={petListWrapper()}>
            {petList?.map((pet) => {
              return (
                <AddPets
                  fields={fields}
                  setShowAddPetsForm={setShowAddPetsForm}
                  key={pet?.PetId}
                  petDetails={pet}
                  removePet={removePet}
                  setIsRemoveModal={setIsRemoveModal}
                  isRemoveModal={isRemoveModal}
                  petId={pet?.PetId}
                  getAllPets={getAllPets}
                  petLoader={petLoader}
                  setIsEdit={setIsEdit}
                  setCurrentPetId={setCurrentPetId}
                  setCurrentEditPetId={setCurrentEditPetId}
                />
              );
            })}
            <AddPets fields={fields} setShowAddPetsForm={setShowAddPetsForm} />
          </div>
        )}
      </div>
      <Banner rendering={rendering} />
    </div>
  );
};
//Banner
export const Banner = ({ rendering }: { rendering?: ComponentRendering }): JSX.Element => {
  const { bannerBase, deskImg } = myPetTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  return (
    <div className={bannerBase()}>
      {rendering && (
        <Placeholder name="personalized-image" rendering={rendering} imageClassName={deskImg()} />
      )}
    </div>
  );
};

/**Add Pets Card */
const AddPets: React.FC<AddPet> = ({
  fields,
  setShowAddPetsForm,
  petDetails,
  setIsRemoveModal,
  petId,
  setCurrentPetId,
  setIsEdit,
  setCurrentEditPetId,
}): JSX.Element => {
  const {
    petDetailsWraper,
    addPetWrapper,
    leftContent,
    rightContent,
    addLabel,
    imageWrapper,
    img,
    link,
    textSmall,
    textSmallBold,
  } = myPetTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  return (
    <div className={addPetWrapper()}>
      <div className={leftContent()}>
        <div className={imageWrapper()}>
          {petDetails && petDetails?.PetImageName ? (
            <NextImage
              field={{
                value: {
                  src: process.env.NEXT_PUBLIC_PSP_PET_CDN + '/' + petDetails?.PetImageName,
                  alt: petDetails?.Petname,
                  width: '104',
                  height: '104',
                },
              }}
              className={img({ className: 'max-w-[104px]' })}
            />
          ) : (
            <NextImage
              field={fields?.defaultPetImage}
              className={img({ className: 'max-w-[104px]' })}
            />
          )}
        </div>
      </div>
      <div className={rightContent()}>
        {petDetails ? (
          <div className={petDetailsWraper({ className: 'py-mob-margin-tight-right lg:py-0' })}>
            <div className={'flex gap-desk-margin-tight-right'}>
              <p className={textSmallBold({ className: 'break-all' })}>{petDetails?.Petname}</p>
              <TextHelper
                field={fields?.editLinkText}
                tag="button"
                className={link()}
                onClickHandler={() => {
                  setShowAddPetsForm && setShowAddPetsForm(true);
                  setIsEdit && setIsEdit(true);
                  setCurrentEditPetId && setCurrentEditPetId(petId);
                }}
              />
            </div>
            <div className={textSmall({ className: 'flex flex-col' })}>
              <p>{petDetails?.BirthdateString}</p>
              <p>{petDetails?.GenderName}</p>
              <p>{petDetails?.SpeciesName}</p>
              <p>{petDetails?.BreedName}</p>
            </div>
            <TextHelper
              field={fields?.removePetLinkText}
              className={link({ className: 'text-left' })}
              tag="button"
              onClickHandler={() => {
                setCurrentPetId && setCurrentPetId(petDetails?.PetId);
                setIsRemoveModal && setIsRemoveModal(true);
              }}
            />
          </div>
        ) : (
          <span
            onClick={() => {
              setShowAddPetsForm && setShowAddPetsForm(true);
            }}
          >
            <TextHelper field={fields?.addPetLinkText} tag="button" className={addLabel()} />
          </span>
        )}
      </div>
    </div>
  );
};
/**Add Pets Form */
const AddPetsForm: React.FC<AddPet> = ({
  fields,
  setShowAddPetsForm,
  getAllPets,
  currentEditPetId,
  isEdit,
  setIsEdit,
  petGenders,
  petSpecies,
  petDetails,
}): JSX.Element => {
  const {
    formWrapper,
    link,
    formFieldWrapper,
    formHeaderWrapper,
    formHeadingTitle,
    textLargeBold,
    textSmall,
    fieldGroup,
    FieldWrapper,
    formInput,
    imageWrapper,
    img,
    textLargeRegular,
    imgFixWidth,
    imageUploadWrapper,
    imageUploadField,
    loaderWrapper,
    genderField,
    dropDownArrow,
    imgSizeErrorMessage,
  } = myPetTailwindVariant({ size: { initial: 'mobile', lg: 'desktop' } });

  //sate
  const [image, setImage] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [isSaveModal, setIsSaveModal] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [gender] = useState<Gender | undefined>(petGenders);
  const [imgSizeError, setImgSizeError] = useState(false);
  const [imgFileTypeError, setFileTypeError] = useState(false);
  const [petDuplicateError, setPetDuplicateError] = useState(false);
  const [imgSize, setImgSize] = useState(0);
  const [species] = useState<Species | undefined>(petSpecies);
  const [speciesId, setSpeciesId] = useState<number>();
  const [speciesError, setSpeciesError] = useState<boolean>(isEdit ? false : true);
  const [birthDate, setBirthDate] = useState<string | number>();
  const [breed, setBreed] = useState<Breed>();

  const { getDictionaryValue } = useDictionary();
  const currentEditingPet: PetResponse[] = isEdit
    ? (petDetails as unknown as PetResponse[])?.filter(
        (pet: PetResponse) => pet?.PetId === currentEditPetId
      )
    : [];
  useEffect(() => {
    const callAPIs = async () => {
      isEdit &&
        getBreed(
          (speciesId?.toString() as string) ||
            (currentEditingPet[0]?.SpeciesId?.toString() as string)
        );
    };
    callAPIs();
  }, [speciesId]);

  /**
   * Info: To fetch breed list
   * @param id Species ID
   */
  const getBreed = async (id: string) => {
    setLoading(true);
    if (id?.length > 0) {
      try {
        const options = {
          method: REQUEST_METHOD?.post,
          data: {
            speciesID: id,
          },
        };
        const res = await apiRequest(petsApi?.getBreed, options);
        setBreed(res as Breed);
      } catch (err) {
        console.error('@@Error', err);
      }
    } else {
      setBreed([]);
    }
    setLoading(false);
  };

  //Handling species error
  const handleErrors = (val: string) => {
    formik?.setFieldValue('Breed', '');
    formik?.setErrors({ Breed: 'required' });
    val?.length <= 0 ? setSpeciesError(true) : setSpeciesError(false);
  };
  //validation Schema
  const validationSchema = yup.object({
    Name: yup
      .string()
      .required(fields?.fieldRequired?.value || '')
      .matches(
        new RegExp(fields?.nameRegex?.value || '^[A-Za-z]+$'),
        fields?.nameValidation?.value
      ),
    Gender: yup.string().required('Required field'),
    Breed: yup.number().required('Required field'),
  });
  const formik = useFormik({
    //set the initial values
    initialErrors: {
      ...(!isEdit && { Species: !isEdit ? 'required field' : '' }),
    },
    initialValues: {
      Name: (isEdit === true && currentEditingPet[0]?.Petname) || '',
      Gender: currentEditingPet[0]?.GenderId || '',
      Species: currentEditingPet[0]?.SpeciesId || '',
      Breed: currentEditingPet[0]?.BreedId || '',
      Image: currentEditingPet[0]?.PetImageName || '',
      Birthdate: isEdit
        ? currentEditingPet[0]?.Birthdate
          ? currentEditingPet[0]?.Birthdate
          : birthDate
          ? birthDate
          : null
        : null,
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    //submitting the Pet details
    onSubmit: async (values) => {
      setSubmitLoading(true);
      try {
        const addPetRequest = {
          method: REQUEST_METHOD?.post,
          data: {
            PetId: currentEditingPet[0]?.PetId,
            petName: values?.Name,
            speciesID: speciesId ?? currentEditingPet[0]?.SpeciesId,
            genderID: values?.Gender,
            breedID: values?.Breed,
            birthdate:
              values?.Birthdate && values?.Birthdate != ''
                ? formatDate(new Date(values?.Birthdate as string))
                : null,
          },
        };

        const addPetImageRequest = {
          method: REQUEST_METHOD?.post,
          data: {
            PetId: '',
            Image: image?.toString()?.replace('data:', '').replace(/^.+,/, ''),
          },
        };
        if (!isEdit) {
          const addPet: PetResponse = await apiRequest(petsApi.addPet, addPetRequest);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (addPet) {
            // setIsSaveModal(true);
            setIsEdit && setIsEdit(false);
            setSubmitLoading(false);

            addPetImageRequest['data'] = {
              ...addPetImageRequest?.data,
              PetId: addPet?.PetId as unknown as string,
            };
          } else {
            setPetDuplicateError(true);
            setSubmitLoading(false);
          }
        } else {
          const updatePet: PetResponse = await apiRequest(petsApi.updatePet, addPetRequest);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (!(updatePet as any)?.Message && updatePet) {
            addPetImageRequest['data'] = {
              ...addPetImageRequest?.data,
              PetId: updatePet?.PetId as unknown as string,
            };
          } else {
            setPetDuplicateError(true);
          }
        }
        image && (await apiRequest(petsApi?.addPetImage, addPetImageRequest));
        setIsSaveModal(true);
        setSubmitLoading(false);
      } catch (err) {
        setPetDuplicateError(true);
        setSubmitLoading(false);
        console.error('@@Error', err);
      }
    },
  });
  //handling image
  const handleImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const img = e?.target?.files?.[0];
    if (img) {
      const size = Number(img?.size / 1000000);
      setImgSize(Math.round(size));
      // const type = img?.type;
      if (size <= 2) {
        setImgSizeError(false);
        const base64Image = (await convertToBase64(
          img as unknown as Blob,
          setFileTypeError
        )) as string;
        setImage(base64Image);
      } else {
        setImage(undefined);
        setImgSizeError(true);
        setFileTypeError(false);
      }
    }
  };

  //Sorting a breed in alphabetic order
  const removeDuplicatesBreedAndSort = (breed: Breed) => {
    const breedMap = new Map();

    breed &&
      breed?.forEach((obj) => {
        // Add object to map if BreedName is not already present
        if (!breedMap?.has(obj.BreedName)) {
          breedMap?.set(obj.BreedName, obj);
        }
      });
    const sortedBreed = Array.from(breedMap.values()).sort((a, b) => {
      if (a?.BreedName?.toString()?.toLowerCase() < b?.BreedName?.toString()?.toLowerCase())
        return -1;
      if (a?.BreedName?.toString()?.toLowerCase() > b?.BreedName?.toString()?.toLowerCase())
        return 1;
      return 0;
    });
    return sortedBreed;
  };

  return (
    <div className={formWrapper()}>
      <SavePetPopUp
        setIsEdit={setIsEdit}
        fields={fields}
        isSaveModal={isSaveModal}
        setIsSaveModal={setIsSaveModal}
        getAllPets={getAllPets}
        setShowAddPetsForm={setShowAddPetsForm}
      />
      <form onSubmit={formik?.handleSubmit}>
        <div className={formHeaderWrapper()}>
          {!isEdit ? (
            <TextHelper
              tag={'p'}
              className={formHeadingTitle()}
              field={fields?.addPetFormHeading}
            />
          ) : (
            <TextHelper
              tag={'p'}
              className={formHeadingTitle()}
              field={fields?.editPetFormHeading}
            />
          )}
          <RichTextHelper className={textSmall()} field={fields?.fieldRequiredLabel} />
          <div className={imageUploadWrapper()}>
            <div
              className={imageWrapper({
                className: imgFixWidth(),
              })}
            >
              <NextImage
                field={{
                  value: {
                    src:
                      image && image?.length > 0
                        ? image
                        : currentEditingPet[0]?.PetImageName &&
                          currentEditingPet[0]?.PetImageName?.length > 0
                        ? (process.env.NEXT_PUBLIC_PSP_PET_CDN || '') +
                          '/' +
                          currentEditingPet[0]?.PetImageName
                        : fields?.defaultPetImage?.value?.src,

                    alt: fields?.defaultPetImage?.value?.alt || petDetails?.Petname || 'MyPet',
                    width: '64',
                    height: '64',
                  },
                }}
                className={img({ className: 'h-[64px] max-h-[64px]' })}
              />
            </div>

            <div className={imageUploadField()}>
              <label htmlFor="Image">
                {!isEdit ? (
                  <TextHelper className={link()} tag="p" field={fields?.addPetImageLinkText} />
                ) : (
                  <TextHelper className={link()} tag="p" field={fields?.editPetImageLinkText} />
                )}
              </label>
              <input
                aria-label="imageUpload"
                type="file"
                id="Image"
                name="Image"
                className="hidden"
                onBlur={formik?.handleBlur}
                onChange={(e) => {
                  formik?.handleChange(e);
                  handleImage(e);
                }}
              />
              <TextHelper
                field={fields?.imageMaximumSize}
                {...(fields?.imageMaximumSize &&
                  fields?.imageMaximumSize?.value?.includes('{0}') && {
                    updatedField: {
                      value: fields?.imageMaximumSize?.value?.replace(
                        '{0}',
                        (Number(fields?.petImageSizeLimit?.value) / 1024).toString() || ''
                      ),
                    },
                  })}
                className={textSmall()}
                tag={'p'}
              />
            </div>
          </div>
          {imgSizeError && (
            <RichTextHelper
              field={fields?.imageMaximumSizeError}
              {...(fields?.imageMaximumSizeError &&
                fields?.imageMaximumSizeError?.value?.includes('{0}' || '{1}') && {
                  updatedField: {
                    value: fields?.imageMaximumSizeError?.value
                      ?.replace(
                        '{0}',
                        (Number(fields?.petImageSizeLimit?.value) / 1024).toString() || ''
                      )
                      ?.replace('{1}', imgSize.toString()),
                  },
                })}
              className={imgSizeErrorMessage()}
            />
          )}
          {imgFileTypeError && (
            <p className={imgSizeErrorMessage()}>{getDictionaryValue('InValidFileType')}</p>
          )}
        </div>
        <div className={formFieldWrapper()}>
          {/**First Wrapper */}
          <div className={fieldGroup()}>
            <div className={FieldWrapper()}>
              <label className={textLargeBold()} htmlFor={fields?.nameText?.value}>
                *<TextHelper field={fields?.nameText} />
              </label>
              <input
                aria-label="input"
                id={fields?.nameText?.value}
                name={fields?.nameText?.value}
                value={formik?.values?.Name}
                className={formInput({ className: textLargeRegular() })}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
              />
              {formik?.errors?.Name && formik?.touched?.Name && (
                <p className="text-system-red">{formik?.errors?.Name}</p>
              )}
            </div>
            <div className={FieldWrapper()}>
              <label className={textLargeBold()} htmlFor={fields?.birthDateText?.value}>
                <TextHelper field={fields?.birthDateText} />
              </label>
              <FormDatePicker
                formik={formik}
                isEdit={isEdit}
                name={fields?.birthDateText?.value}
                setBirthDate={setBirthDate}
                birthDate={birthDate}
                selectedDate={currentEditingPet[0]?.Birthdate as string}
              />
            </div>
          </div>
          {/**Second Wrapper */}
          <div className={fieldGroup()}>
            <div className={FieldWrapper()}>
              <label className={textLargeBold()} htmlFor={fields?.speciesText?.value}>
                *<TextHelper field={fields?.speciesText} />
              </label>
              <select
                aria-label="species"
                id={fields?.speciesText?.value}
                className={formInput({
                  className: textLargeRegular({
                    className: dropDownArrow(),
                  }),
                })}
                onBlur={formik?.handleBlur}
                onChange={(e) => {
                  getBreed(e?.target?.value),
                    setSpeciesId(Number(e?.target?.value)),
                    handleErrors(e?.target?.value);
                }}
              >
                <option key={'select option'} value={''}>
                  <TextHelper field={fields?.selectOptionText} />
                </option>
                {species &&
                  species?.length > 0 &&
                  species?.map((spec) => {
                    return (
                      <option
                        value={spec?.SpeciesId}
                        key={spec?.SpeciesId}
                        selected={currentEditingPet[0]?.SpeciesId === spec?.SpeciesId}
                      >
                        {spec?.SpeciesName}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className={FieldWrapper()}>
              <label className={textLargeBold()} htmlFor={fields?.breedText?.value}>
                *<TextHelper field={fields?.breedText} />
              </label>
              <select
                aria-label="breeds"
                id={fields?.breedText?.value}
                className={formInput({
                  className: textLargeRegular({
                    className: dropDownArrow(),
                  }),
                })}
                onBlur={formik?.handleBlur}
                defaultValue={currentEditingPet[0]?.BreedId || ''}
                onChange={formik?.handleChange}
              >
                <option value={''}>
                  {loading ? (
                    <TextHelper field={fields?.genericSpinnerText} />
                  ) : (
                    <TextHelper field={fields?.selectOptionText} />
                  )}
                </option>
                {breed &&
                  removeDuplicatesBreedAndSort(breed)?.map((b) => {
                    return (
                      <option
                        value={b?.BreedId}
                        key={b?.BreedId}
                        selected={currentEditingPet[0]?.BreedId.toString() == b?.BreedId}
                      >
                        {b?.BreedName}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className={FieldWrapper()}>
            <span className={textLargeBold()}>
              *<TextHelper field={fields?.genderText} />
            </span>
            <div className={FieldWrapper()}>
              <div className={fieldGroup({ className: '!flex-row' })}>
                {gender &&
                  gender?.map((genItem) => {
                    return (
                      <div key={genItem?.GenderId} className={genderField()}>
                        <input
                          aria-label="gender"
                          type="radio"
                          value={genItem?.GenderId}
                          name={'Gender'}
                          id={genItem?.GenderName}
                          onBlur={formik?.handleBlur}
                          onChange={formik?.handleChange}
                          defaultChecked={currentEditingPet[0]?.GenderId === genItem?.GenderId}
                        />
                        <label htmlFor={genItem?.GenderName}>{genItem?.GenderName}</label>
                      </div>
                    );
                  })}
              </div>
              {formik?.errors?.Gender && formik?.touched?.Gender && (
                <span className="text-system-red">{formik?.errors?.Gender}</span>
              )}
            </div>
          </div>
          {petDuplicateError && (
            <div className={fieldGroup()}>
              <TextHelper
                field={fields?.duplicatePetError}
                className="text-system-red"
                tag={'span'}
              />
            </div>
          )}
          <div className={fieldGroup()}>
            {submitLoading ? (
              <div className={loaderWrapper()}>
                <Loader />
                <TextHelper field={fields?.genericSpinnerText} />
              </div>
            ) : isEdit ? (
              <ButtonHelper
                field={fields?.updateButtonText as Field<string>}
                isLinkField={false}
                size="medium"
                variant={
                  !(
                    formik.isValid &&
                    formik?.dirty &&
                    !speciesError &&
                    // !imgSizeError &&
                    !imgFileTypeError
                  )
                    ? 'disabled'
                    : 'primary'
                }
                className="w-full"
                isDisabled={
                  !(
                    formik.isValid &&
                    formik?.dirty &&
                    !speciesError &&
                    // !imgSizeError &&
                    !imgFileTypeError
                  )
                }
              />
            ) : (
              <ButtonHelper
                field={fields?.saveButtonText as Field<string>}
                isLinkField={false}
                size="medium"
                variant={
                  !(formik.isValid && !speciesError && !imgFileTypeError) ? 'disabled' : 'primary'
                }
                className="w-full"
                isDisabled={!(formik.isValid && !speciesError && !imgFileTypeError)}
              />
            )}
            <ButtonHelper
              field={fields?.cancelButtonText as Field<string>}
              isLinkField={false}
              size="medium"
              variant="secondary"
              className="w-full"
              onClickHandler={(e) => {
                e?.preventDefault();
                setShowAddPetsForm && setShowAddPetsForm(false);
                setIsEdit && setIsEdit(false);
              }}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

/** Date Picker*/
const FormDatePicker: React.FC<{
  isEdit?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  birthDate?: any;
  setBirthDate: React.Dispatch<React.SetStateAction<string>>;
  selectedDate: string;
  name?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik?: any;
}> = ({ setBirthDate, selectedDate, isEdit, formik, name }) => {
  const { textLargeBold } = myPetTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });

  const [date, setDate] = useState<Date>();
  return (
    <DatePicker
      selected={
        isEdit ? (date ? date : selectedDate ? new Date(selectedDate) : null) : date ? date : null
      }
      weekDayClassName={() => textLargeBold({ className: 'flex w-full justify-center' })}
      onChange={(date) => {
        formik?.setFieldValue('Birthdate', date);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setDate(date as any), setBirthDate(formatDate(date as Date));
      }}
      maxDate={new Date()}
      name={name}
      monthClassName={() => 'text-lg'}
      placeholderText="mm/dd/year"
      className="[&>*>*>button]:bg-color-brand-primary-1-base"
    />
  );
};
//remove pet warning modal
const RemovePetPopUp: React.FC<AddPet> = ({
  fields,
  removePet,
  isRemoveModal,
  getAllPets,
  setIsRemoveModal,
  petId,
}): JSX.Element => {
  const { wrapper, title, btnWrapper, btnPrimary, btnSecondary } = removePetPopUpTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const [removeLoading, setRemoveLoading] = useState(false);
  useEffect(() => {
    isRemoveModal && isRemoveModal
      ? document.body.classList.add('overflow-hidden')
      : document.body.classList.remove('overflow-hidden');
  }, [isRemoveModal]);

  return (
    <ModalWrapper
      showModal={isRemoveModal}
      onCloseClick={() => {
        setIsRemoveModal && setIsRemoveModal(false);
      }}
      additionalClassForPopUpModal={`!item-start !h-fit translate-y-0  animate-[topAnimation_0.3s_ease-in-out]`}
      popupWidth="max-w-[995px]"
      showCloseButtonInModalHeader={false}
      popupBG="bg-[rgba(0,0,0,0.65)]"
      closeModalOnOverlayClick
      customPopup
    >
      <div className={wrapper()}>
        <TextHelper field={fields?.deletePetModalHeading} tag="p" className={title()} />
        <TextHelper field={fields?.deletePetModalMessage} tag="p" className="mt-8" />
        {removeLoading && <Loader />}
        <div className={btnWrapper()}>
          {fields?.deleteButtonText && fields?.deletePetCancelButtonText && (
            <>
              <TextHelper
                field={fields?.deleteButtonText}
                tag={'button'}
                className={btnPrimary()}
                onClickHandler={async () => {
                  setRemoveLoading(true);
                  removePet && petId && (await removePet(petId));
                  getAllPets && (await getAllPets());
                  setTimeout(() => {
                    setRemoveLoading(false);
                    setIsRemoveModal && setIsRemoveModal(false);
                  }, 1000);
                }}
              />
              <TextHelper
                field={fields?.deletePetCancelButtonText}
                tag={'button'}
                className={btnSecondary()}
                onClickHandler={() => {
                  setIsRemoveModal && setIsRemoveModal(false);
                }}
              />
            </>
          )}
        </div>
      </div>
    </ModalWrapper>
  );
};
//save modal
const SavePetPopUp: React.FC<AddPet> = ({
  fields,
  isSaveModal,
  setIsSaveModal,
  getAllPets,
  setIsEdit,
  setShowAddPetsForm,
}): JSX.Element => {
  const { wrapper, title, btnWrapper, btnPrimary } = removePetPopUpTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  useEffect(() => {
    isSaveModal && isSaveModal
      ? document.body.classList.add('overflow-hidden')
      : document.body.classList.remove('overflow-hidden');
  }, [isSaveModal]);
  return (
    <ModalWrapper
      showModal={isSaveModal}
      onCloseClick={async () => {
        setIsSaveModal && setIsSaveModal(false);
        getAllPets && (await getAllPets());
        setShowAddPetsForm && setShowAddPetsForm(false);
      }}
      additionalClassForPopUpModal={`!item-start !h-fit translate-y-0  animate-[topAnimation_0.3s_ease-in-out]`}
      popupWidth="max-w-[995px]"
      showCloseButtonInModalHeader={false}
      popupBG="bg-[rgba(0,0,0,0.65)]"
      closeModalOnOverlayClick
      customPopup
    >
      <div className={wrapper()}>
        <TextHelper field={fields?.savePetInfoTitle} tag="p" className={title()} />
        <TextHelper field={fields?.savePetInfoBody} tag="p" className="mt-8" />
        <div className={btnWrapper()}>
          {fields?.deleteButtonText && fields?.deletePetCancelButtonText && (
            <TextHelper
              field={fields?.savePetButtonText}
              tag={'button'}
              className={btnPrimary()}
              onClickHandler={async () => {
                setIsEdit && setIsEdit(false);
                setIsSaveModal && setIsSaveModal(false);
                getAllPets && (await getAllPets());
                setShowAddPetsForm && setShowAddPetsForm(false);
              }}
            />
          )}
        </div>
      </div>
    </ModalWrapper>
  );
};
//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<MyPetProps>(MyPet);
