import { ImageField, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import ImageHelper from 'src/helpers/commonComponents/ImageHelper';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import React, { useContext, useEffect } from 'react';
import { ComponentProps } from 'lib/component-props';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import { useBreakpoints } from 'src/utils/breakpoints';
import { useOcSelector } from 'src/redux/ocStore';
import {
  formatDateForGTM,
  notAvailableIfNullOrEmpty,
  trackObjectForPromotion,
} from 'src/utils/sendGTMEvent';
import { GTM_EVENT } from 'src/helpers/Constants';
import { PSP } from 'models/PetSuppliesPlus.Model';
import useIntersectionObserver from 'src/hooks/useIntersectionObserver';
import { PromotionData } from './PersonalizedImage';
import ComponentContext from 'lib/context/ComponentContext';

type BasePersonalizedImageProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.PersonlizedImage.Fields.PersonalizedImageBanner & {
    fields: { personalizedLinkUrl?: LinkField; personalizedImage?: ImageField };
    imageClassName?: string; // class name for tailwind css for image styling
    children?: React.ReactNode; // additional elements to be rendered
  };

const BasePersonalizedImage = ({
  fields,
  imageClassName,
  children,
}: BasePersonalizedImageProps) => {
  const isEE = useExperienceEditor();
  const imageSrc = fields?.personalizedImage?.value?.src?.replace('jssmedia', 'media');
  const { deviceName }: { deviceName: string } = useBreakpoints();
  const myStoreId = useOcSelector((state) => state?.storeReducer?.selectedStore.storeId) as string;

  const { ref, isVisible } = useIntersectionObserver<HTMLDivElement>({
    root: null, // Use the viewport as the root
    rootMargin: '0px',
    threshold: 0.1, // Trigger when 10% of the component is visible
  });
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const sendGTM = (data: PromotionData, event = GTM_EVENT?.viewPromotion) => {
    const gtmPromotion = [
      {
        promotion_id: notAvailableIfNullOrEmpty(data?.promotionId?.value),
        promotion_name: notAvailableIfNullOrEmpty(data?.promotionName?.value),
        creative_name: notAvailableIfNullOrEmpty(data?.componentName?.value),
        creative_slot: Number(data?.creativeSlotNumber?.value),
        promotion_device: deviceName,
        promotion_copy: notAvailableIfNullOrEmpty(data?.promotionCopy?.value),
        promotion_dates: notAvailableIfNullOrEmpty(
          formatDateForGTM(`${data?.promotionDateFROM?.value} - ${data?.promotionDateTO?.value}`)
        ),
        promotion_cta: notAvailableIfNullOrEmpty(data?.cTAButtonCopy?.value),
        promotion_url: notAvailableIfNullOrEmpty(data?.promotionURL?.value?.href),
      },
    ];
    trackObjectForPromotion(event, myStoreId, gtmPromotion);
  };

  useEffect(() => {
    if (myStoreId && fields?.componentName?.value !== '' && isVisible) {
      sendGTM(fields);
    }
  }, [myStoreId, isVisible]);
  return (
    <>
      {isEE ? (
        <div
          className="component in-EE"
          data-personalizedLinkUrl={JSON.stringify(fields?.personalizedImage)}
        >
          <img src={imageSrc} alt="" />
        </div>
      ) : (
        <div
          ref={ref}
          className="component"
          data-component={'components/PersonalizedImage/PersonalizedImage'}
          data-creative-id={fields?.componentName?.value}
          data-promotion-cta={fields?.cTAButtonCopy?.value}
          data-promo-id={fields?.promotionId?.value}
          data-promotion-name={fields?.promotionName?.value}
          data-promotion-copy={fields?.promotionCopy?.value}
          dta-promotion-url={fields?.promotionURL?.value?.href}
        >
          <LinkHelper
            field={fields?.personalizedLinkUrl}
            className={'block'}
            onClick={() => {
              if (myStoreId && fields?.componentName?.value !== '') {
                sendGTM(fields, GTM_EVENT?.selectPromotion);
              }

              setcomponentContextData({
                ...componentContextData,
                closeNavigation: true,
              });
            }}
          >
            {fields?.personalizedImage && (
              <ImageHelper field={fields?.personalizedImage} className={imageClassName} />
            )}
          </LinkHelper>
          {children}
        </div>
      )}
    </>
  );
};

export default BasePersonalizedImage;
