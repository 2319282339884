import { Dispatch, SetStateAction, createContext } from 'react';
import { LineItemWithXp } from 'src/redux/xp';

export interface ComponentContextData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;

  lineItem?: LineItemWithXp[];
  isDFSMethodChanged?: boolean;
  showDeliveryAvailabilityPopup?: boolean;
  openMiniCart?: boolean;
  outOfStockProductIds?: string[];
  showDeliveryThreashold?: boolean;
}

interface ComponentContextProps {
  componentContextData: ComponentContextData;
  setcomponentContextData: Dispatch<SetStateAction<ComponentContextData>>;
}

const ComponentContext = createContext<ComponentContextProps>({
  componentContextData: {},
  setcomponentContextData: (_state: ComponentContextData) => {
    _state;
  },
});

export default ComponentContext;
