import withPersonalization from 'src/helpers/withPersonalization/withPersonalization';
import BasePersonalizedImage from './BasePersonalizedImage';
import { Field, ImageField, LinkField, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import clsx from 'clsx';
import {
  formatDateForGTM,
  notAvailableIfNullOrEmpty,
  trackObjectForPromotion,
} from 'src/utils/sendGTMEvent';
import { GTM_EVENT } from 'src/helpers/Constants';
import { useBreakpoints } from 'src/utils/breakpoints';
import { useOcSelector } from 'src/redux/ocStore';
import { PSP } from 'models/PetSuppliesPlus.Model';
import { personalizedImageTailwindVariant } from 'tailwindVariants/components/personalizedImageTailwindVariant';
import { useContext } from 'react';
import ComponentContext from 'lib/context/ComponentContext';

const { linkWrapper, contentWrapper } = personalizedImageTailwindVariant({
  size: { initial: 'mobile', lg: 'desktop' },
});

export type PromotionData = {
  personalizedLinkUrl?: LinkField;
  personalizedImage?: ImageField;
  personalizedTitle?: Field<string>;
  promotionName?: Field<string>;
  promotionId?: Field<string>;
  componentName?: Field<string>;
  creativeSlotNumber?: Field<string>;
  promotionCopy?: Field<string>;
  promotionDateFROM?: Field<string>;
  promotionDateTO?: Field<string>;
  cTAButtonCopy?: Field<string>;
  promotionURL?: LinkField;
};
type PersonalizedImageProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.Base.Fields.IPersonalization & {
    fields: PromotionData;
    imageClassName?: string; // class name for tailwind css for image styling
  };

export const Default = withPersonalization((props: PersonalizedImageProps) => (
  <BasePersonalizedImage {...props} />
));

export const OnlyImage = withPersonalization((props: PersonalizedImageProps) => (
  <BasePersonalizedImage {...props} />
));

export const BottomTitle = withPersonalization((props: PersonalizedImageProps) => {
  const { deviceName }: { deviceName: string } = useBreakpoints();
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const myStoreId = useOcSelector((state) => state?.storeReducer?.selectedStore.storeId) as string;
  const gtmPromotion = [
    {
      promotion_id: notAvailableIfNullOrEmpty(props?.fields?.promotionId?.value),
      promotion_name: notAvailableIfNullOrEmpty(props?.fields?.promotionName?.value),
      creative_name: notAvailableIfNullOrEmpty(props?.fields?.componentName?.value),
      creative_slot: Number(props?.fields?.creativeSlotNumber?.value),
      promotion_device: deviceName,
      promotion_copy: notAvailableIfNullOrEmpty(props?.fields?.promotionCopy?.value),
      promotion_dates: notAvailableIfNullOrEmpty(
        formatDateForGTM(
          `${props?.fields?.promotionDateFROM?.value} - ${props?.fields?.promotionDateTO?.value}`
        )
      ),
      promotion_cta: notAvailableIfNullOrEmpty(props?.fields?.cTAButtonCopy?.value),
      promotion_url: notAvailableIfNullOrEmpty(props?.fields?.promotionURL?.value?.href),
    },
  ];
  return (
    <BasePersonalizedImage {...props}>
      <div className={contentWrapper()}>
        <LinkHelper
          field={props?.fields?.personalizedLinkUrl}
          className={clsx(linkWrapper())}
          onClick={() => {
            myStoreId &&
              props?.fields?.componentName?.value !== '' &&
              trackObjectForPromotion(GTM_EVENT?.selectPromotion, myStoreId, gtmPromotion);
          }}
        >
          {props?.fields?.personalizedTitle && (
            <Text
              field={props?.fields?.personalizedTitle}
              tag="span"
              onClick={() => {
                setcomponentContextData({
                  ...componentContextData,
                  closeNavigation: true,
                });
              }}
            />
          )}
        </LinkHelper>
      </div>
    </BasePersonalizedImage>
  );
});
