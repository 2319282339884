import { LinkField, LinkFieldValue } from '@sitecore-jss/sitecore-jss-nextjs';

/**
 * Normalizes url path replacing ` ` with `-` and converting to lowercase
 * */
export function normalizePath(path?: string) {
  // Find the index of '#' and '?'
  if (!path) {
    return '';
  }
  const hashIndex = path.indexOf('#');
  const queryIndex = path.indexOf('?');

  // Find the minimum index of anchor and query
  const minIndex = Math.min(
    hashIndex !== -1 ? hashIndex : Infinity,
    queryIndex !== -1 ? queryIndex : Infinity
  );

  // Extract the hostname
  const basePath = path.slice(0, minIndex);
  const queryHash = path.slice(minIndex);
  // Only convert base path
  return basePath.replaceAll('%20', '-').toLowerCase() + queryHash;
}
/**
 * Splits out the querystring and hash if it's passed as part of the href.
 * Normalizes it so it's in the correct format and doesn't hit the redirect
 */

export function normalizeLinkField(linkField?: LinkFieldValue): LinkFieldValue {
  // Initialize variable to hold URL parts
  const urlParts = { ...linkField };

  const url = linkField?.href;
  if (!url) {
    return urlParts;
  }

  // Find the index of '#' and '?'
  /**
   * Following value denotes following circumstances:
   * 0 or any other value: Exists at that particular index
   * -1 denotes absence of that field
   */
  const hashIndex = url.indexOf('#');
  const queryIndex = url.indexOf('?');

  // Extract anchor if it exists
  if (!urlParts.anchor && hashIndex > -1) {
    urlParts.anchor = url.slice(hashIndex + 1, hashIndex < queryIndex ? queryIndex : undefined);
  }

  if (!urlParts.querystring && queryIndex > -1) {
    urlParts.querystring = url.slice(
      queryIndex + 1,
      queryIndex < hashIndex ? hashIndex : undefined
    );
  }

  if (!urlParts.linktype) {
    // Determine link type (internal or external)
    urlParts.linktype = url.startsWith('/') ? 'internal' : 'external';
  }

  // Find the minimum index of anchor and query
  const minIndex = Math.min(
    hashIndex !== -1 ? hashIndex : Infinity,
    queryIndex !== -1 ? queryIndex : Infinity
  );

  // Extract the path without querystring or hash
  let path = url.slice(0, minIndex);

  // This is important, because now it will be in the correct format and won't get redirected.
  // Redirect breaks hash because browser does not send hash to server
  path = normalizePath(path?.replaceAll('%20', '-'));

  // Sitecore doesn't render link correctly without hash in href, so add it back in
  if (urlParts.anchor && urlParts.linktype == 'anchor') {
    // Need to add an exception for only `anchor` linktype as it breaks other links like `internal` link:
    path += '#' + urlParts.anchor;
  }

  urlParts.href = path;

  // Return the extracted URL parts
  return urlParts;
}

/** Earlier Code
 * Helper function to process the href and querystring
 * Used as : const processedField = processLinkField(field as LinkField);
 */
export const processLinkField = (fields: LinkField): LinkField => {
  if (fields?.value?.href) {
    const href = fields?.value?.href;
    if (href && href.includes('?')) {
      const [baseUrl, queryString] = href.split('?');
      fields.value.href = baseUrl;
      fields.value.querystring = queryString;
    }
  }
  return fields;
};

export const decodeUrlQuery = (linkHref: string) => {
  if (!linkHref) return '/';
  if (linkHref?.includes('q')) {
    if (linkHref?.includes('#')) {
      const queryString = linkHref?.split('#');
      const modifiedLink = decodeURIComponent(queryString?.[0]) + '#' + queryString?.[1];
      return modifiedLink;
    }
    return decodeURIComponent(linkHref);
  }
  return linkHref;
};
