import { Field, ImageField, NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { PSP } from 'models/PetSuppliesPlus.Model';
import { useState, useEffect, useRef, useContext } from 'react';
import { useCookies } from 'react-cookie';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import ComponentContext from 'lib/context/ComponentContext';
import {
  FilterGeo,
  PageController,
  WidgetDataType,
  useSearchResults,
  widget,
} from '@sitecore-search/react';
import { setStore } from 'src/redux/storeDetailsSlice';
import { updateMyStore } from 'src/helpers/StoreHelper';
import useDictionary from 'src/hooks/useDictionary';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import { convertMilesToKM } from 'src/utils/milestokm';
import { goeLocationPopupTailwindVariant } from 'tailwindVariants/components/goeLocationPopupTailwindVariant';
import ModalWrapper from 'src/helpers/commonComponents/ModalWrapper';
import ButtonHelper from 'src/helpers/commonComponents/Button';
import urlConfig from 'src/utils/urlConfig';
import { TimerStore } from 'lib/timerStore';
import { retrieveOrder } from 'src/redux/ocCurrentOrder';

export type GeoLocationPopupProps = GeoLocationProps &
  PSP.Sitecore.templates.PetSuppliesPlus.GeolocationPopup.Fields.GeoLocationPopupItem & {
    rfkId?: string;
  };

// Remove the custom types when the PSP.Sitecore.templates is fixed
type GeoLocationProps = {
  fields: {
    data: {
      datasource: DataSourceType;
    };
  };
};

type DefaultStoreType = {
  targetItem: {
    city: FieldType<string>;
    latitude: FieldType<string>;
    jsonValue: FieldType<string>;
    longitude: FieldType<string>;
    state: FieldType<string>;
    storeid: FieldType<string>;
    storename: FieldType<string>;
  };
};

type DataSourceType = {
  changeStoreCTAText: FieldType<string>;
  continueShoppingCTAText: FieldType<string>;
  defaultStore: DefaultStoreType;
  neighborhoodText: FieldType<string>;
  radiusInMiles?: FieldType<number>;
  rfkid?: FieldType<string>;
  logo: {
    jsonValue: ImageField;
  };
  neighborhoodTextNotFound: {
    jsonValue: Field<string>;
  };
  changeStoreCTATextNotFound: {
    jsonValue: Field<string>;
  };
};

type FieldType<T> = {
  jsonValue: Field<T>;
};

const MODAL_GEOLOCATION_KEY = 'modal-geolocation-displayed';

function GeolocationPopupDetail({ fields }: GeoLocationPopupProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showStoreBasedOnLocation, setShowStoreBasedOnLocation] = useState<
    | {
        lat: number;
        lng: number;
      }
    | false
  >(false);
  const { getDictionaryValue } = useDictionary();
  const [storeNotFound, setStoreNotFound] = useState(false);
  const [isStoreChanged, setIsStoreChanged] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [cookies, setCookie] = useCookies([
    'MyLatitude',
    'MyLongitude',
    MODAL_GEOLOCATION_KEY,
    'MyStoreId',
  ]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectedStore = useOcSelector((state: any) => state?.storeReducer?.selectedStore);
  // useRef to track whether Google Maps API has been called
  const googleApiCalledRef = useRef(false);
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const geoLocationModalTimeout = Number(process.env.NEXT_PUBLIC_GEOLOCATION_MODAL_TIMEOUT) || 10;

  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 365);

  // Custom logic to execute on Modal close - currently it seems it may not needed
  const handleModalClose = () => {
    setCookie(MODAL_GEOLOCATION_KEY, 'true', { expires: expirationDate });
    if (!cookies?.MyStoreId)
      setCookie('MyStoreId', selectedStore?.storeId, { expires: expirationDate });

    setIsModalOpen(false);
  };
  const {
    queryResult: { isFetching, isLoading, data: { content: storesList = [], errors = [] } = {} },
    query,
  } = useSearchResults({
    state: {
      itemsPerPage: 1,
      sortType: 'near_by_distance_asc',
    },
    query: (query) => {
      const geoFilter = new FilterGeo(
        'location',
        `${convertMilesToKM(fields?.data?.datasource?.radiusInMiles?.jsonValue?.value || 50)}km` //adding fallback value as 50 because currently on dev there no radiusInMiles field
      );
      query.getRequest().setSearchFilter(geoFilter);
      query.setEnabled(false);
    },
  });

  useEffect(() => {
    const modalShown = cookies?.[MODAL_GEOLOCATION_KEY];

    if ((!isFetching || !isLoading) && !isStoreChanged && !modalShown && showStoreBasedOnLocation) {
      if (storesList?.length > 0 && storesList[0]?.firstname && !(errors?.length > 0)) {
        changeMyStore();
        setStoreNotFound(false);
      } else {
        setStoreNotFound(true);
      }
      setIsStoreChanged(true);
    }
  }, [cookies?.[MODAL_GEOLOCATION_KEY], storesList, showStoreBasedOnLocation]);

  useEffect(() => {
    if (cookies?.MyStoreId) {
      changeFromDefaultToMyStore();
    }
  }, []);

  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [isClient, setIsClient] = useState<boolean>(false);

  // To prevent in SSG rendering.
  useEffect(() => {
    setIsClient(true);
  }, []);

  // Logic to keep global popup render after geoLocationModalTimeout.
  useEffect(() => {
    if (!isClient) return undefined;
    const checkModalCookie = cookies?.[MODAL_GEOLOCATION_KEY];

    // Set the dynamic delay in milliseconds
    TimerStore.setPopupDelay(geoLocationModalTimeout * 1000);

    // If popup has been shown before, don't show it again
    if (TimerStore.hasBeenShown()) {
      return undefined;
    }

    const remainingTime = TimerStore.getRemainingTime();

    if (remainingTime === 0 && !checkModalCookie) {
      setShowPopup(true);
      setIsModalOpen(true);
    } else {
      // If timer hasn't started, start it
      if (remainingTime === geoLocationModalTimeout * 1000) {
        TimerStore.startTimer();
      }

      // Set timeout for remaining time
      const timer = setTimeout(() => {
        if (!checkModalCookie) {
          setShowPopup(true);
          setIsModalOpen(true);
        }
      }, remainingTime);

      return () => clearTimeout(timer);
    }
    return undefined;
  }, [isClient, TimerStore.getRemainingTime()]);

  // Get Location from Google Maps:
  const getLocationFromGoogleMaps = () => {
    if (googleApiCalledRef.current) return; // If Google API was already called, do not call again
    googleApiCalledRef.current = true;
    fetch(urlConfig?.getLocationFromGoogleMapsAPIPath + process.env.NEXT_PUBLIC_GOOGLE_API_KEY, {
      method: 'POST',
      body: JSON.stringify({}),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.location) {
          const { lat, lng } = data.location;
          PageController.getContext().setGeo({
            location: { lat: lat, lon: lng },
          });
          query.setEnabled(true);
          setCookie('MyLatitude', lat);
          setCookie('MyLongitude', lng);
          setShowStoreBasedOnLocation({
            lat: lat,
            lng: lng,
          });
          const checkModalCookie = cookies?.[MODAL_GEOLOCATION_KEY];
          if (!checkModalCookie) {
            setShowPopup(true);
          }
        } else {
          console.log('Failed to retrieve location from Google Maps.');
        }
      })
      .catch((err) => {
        console.log('Error fetching location from Google Maps.', err);
      });
  };

  useEffect(() => {
    const modalShown = cookies?.[MODAL_GEOLOCATION_KEY];
    if (!modalShown && navigator.geolocation) {
      const timeout = setTimeout(() => {
        getLocationFromGoogleMaps();
        if (!modalShown) {
          setIsModalOpen(true);
        } else {
          setIsModalOpen(false);
        }
      }, geoLocationModalTimeout * 1000); // Timeout after 15 seconds
      navigator.geolocation.getCurrentPosition(
        (position) => {
          clearTimeout(timeout);
          // User granted permission and we got the location
          PageController.getContext().setGeo({
            location: { lat: position?.coords?.latitude, lon: position?.coords?.longitude },
          });
          query.setEnabled(true);
          setCookie('MyLatitude', position?.coords?.latitude);
          setCookie('MyLongitude', position?.coords?.longitude);
          setShowStoreBasedOnLocation({
            lat: position?.coords?.latitude,
            lng: position?.coords?.longitude,
          });

          const checkModalCookie = cookies?.[MODAL_GEOLOCATION_KEY];
          if (!checkModalCookie) {
            setShowPopup(true);
          }
        },
        (error) => {
          // User denied permission or other errors
          clearTimeout(timeout);
          if (!googleApiCalledRef.current) {
            if (error.code === 1 || error.code === 3) {
              getLocationFromGoogleMaps(); // Call Google API if user denies or times out
            }
          }
        },
        {
          timeout: geoLocationModalTimeout * 1000, // Optional: geolocation API will also timeout if the request takes too long and covert sec into milliseconds
          enableHighAccuracy: true,
        }
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
    let timer: NodeJS.Timeout;

    if (!modalShown) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }

    const handleClickOutsideModal = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).closest('.StoreLocatorGlobalModal') &&
        !(event.target as HTMLElement).closest('.pac-container') &&
        !(event.target as HTMLElement).closest('.WarningStoreChangeModal') &&
        !(event.target as HTMLElement).closest('.cart-warning-popup') &&
        !(event.target as HTMLElement).closest('.cookiePopup') &&
        !(event.target as HTMLElement).closest('#header')
      ) {
        // handleModalClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutsideModal);

    return () => {
      clearTimeout(timer);
      document.removeEventListener('mousedown', handleClickOutsideModal);
    };
  }, []);
  const {
    base,
    container,
    modalPanel,
    modalContent,
    content,
    logo,
    text,
    shoppingButtonWrapper,
    shoppingButton,
    storeButtonWrapper,
    storeButton,
    linkIcon,
    storeDetailWrapper,
    // missingStoreButtonWrapper,
  } = goeLocationPopupTailwindVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const dispatch = useOcDispatch();

  const changeMyStore = async () => {
    if (storesList?.[0]?.storeid !== selectedStore?.storeId) {
      await dispatch(setStore(await updateMyStore(storesList?.[0]?.storeid)));
      await dispatch(retrieveOrder());
      setCookie('MyStoreId', storesList?.[0]?.storeid, { expires: expirationDate }); // set the selected store in cookie
    }
  };

  const changeFromDefaultToMyStore = async () => {
    await dispatch(setStore(await updateMyStore(cookies?.MyStoreId)));
    await dispatch(retrieveOrder());
  };

  return (
    <div className={base()}>
      <div className={container()}>
        <ModalWrapper
          showCloseButtonInModalHeader={false}
          closeModalOnOverlayClick={true}
          onCloseClick={handleModalClose}
          showModal={
            showPopup &&
            isModalOpen &&
            !storeNotFound &&
            showStoreBasedOnLocation &&
            (!isFetching || !isLoading) &&
            storesList?.length > 0 &&
            storesList[0]?.firstname &&
            !(errors?.length > 0)
          }
          customPopup={true}
          popupWidth="max-w-[997px]"
          popupSpacing="p-0"
          additionalClassForModal={'!z-[1150]'} // Global Location Popup
        >
          <>
            <div className={modalPanel()} ref={modalRef}>
              <div className={modalContent()}>
                {fields?.data?.datasource?.logo?.jsonValue?.value && (
                  <div className={logo()}>
                    <NextImage
                      field={fields?.data?.datasource?.logo?.jsonValue}
                      width={302}
                      height={69}
                      priority
                    />
                  </div>
                )}
                <div className={content()}>
                  {!storeNotFound ? (
                    <>
                      <div className={storeDetailWrapper()}>
                        {fields?.data?.datasource?.neighborhoodText?.jsonValue?.value && (
                          <RichText
                            className={text()}
                            field={fields?.data?.datasource?.neighborhoodText?.jsonValue}
                          />
                        )}
                        <p className={text()}>
                          {showStoreBasedOnLocation &&
                          (!isFetching || !isLoading) &&
                          storesList?.length > 0 &&
                          storesList[0]?.firstname &&
                          !(errors?.length > 0)
                            ? storesList[0]?.firstname
                            : selectedStore?.storeName}
                          <span className="ml-1">{getDictionaryValue('Store') || 'Store'}</span>
                        </p>
                      </div>
                      {fields?.data?.datasource?.continueShoppingCTAText?.jsonValue?.value && (
                        <div className={shoppingButtonWrapper()}>
                          <ButtonHelper
                            field={fields?.data?.datasource?.continueShoppingCTAText?.jsonValue}
                            variant={'primary'}
                            isLinkField={false}
                            size="default"
                            className={shoppingButton()}
                            onClickHandler={handleModalClose}
                          />
                        </div>
                      )}
                      {fields?.data?.datasource?.changeStoreCTAText?.jsonValue && (
                        <div className={storeButtonWrapper()}>
                          <LinkHelper
                            field={{
                              value: {
                                text: fields?.data?.datasource?.changeStoreCTAText?.jsonValue
                                  ?.value,
                                href: '/',
                              },
                            }}
                            onClick={(e) => {
                              e?.preventDefault();
                              setcomponentContextData({
                                ...componentContextData,
                                showStoreLocatorGlobalModal: true,
                                callbackFunctionForStoreLocatorGlobalModal: () => {
                                  handleModalClose();
                                },
                              });
                            }}
                            className={storeButton()}
                          >
                            <>
                              <Text
                                field={fields?.data?.datasource?.changeStoreCTAText?.jsonValue}
                                tag="p"
                              />
                              <IconHelper className={linkIcon()} icon={'chevron-right'} />
                            </>
                          </LinkHelper>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {/* //TODO: We will cover this later */}
                      {/* {fields?.data?.datasource?.neighborhoodTextNotFound?.jsonValue && (
                        <RichText
                          className={text()}
                          field={fields?.data?.datasource?.neighborhoodTextNotFound?.jsonValue}
                        />
                      )}
                      <div className={missingStoreButtonWrapper()}>
                         {fields?.data?.datasource?.changeStoreCTATextNotFound?.jsonValue && (
                        <div className={storeButtonWrapper()}>
                          <LinkHelper
                            field={{
                              value: {
                                text: fields?.data?.datasource?.changeStoreCTATextNotFound?.jsonValue
                                  ?.value,
                                href: '/',
                              },
                            }}
                            onClick={(e) => {
                              e?.preventDefault();
                              setcomponentContextData({
                                ...componentContextData,                               
                                showStoreLocatorGlobalModal: true,
                              });
                            }}
                            className={storeButton()}
                          >
                            <>
                              <Text
                                field={fields?.data?.datasource?.changeStoreCTATextNotFound?.jsonValue}
                                tag="p"
                              />
                              <IconHelper className={linkIcon()} icon={'chevron-right'} />
                            </>
                          </LinkHelper>
                        </div>
                      )}
                      </div> */}
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        </ModalWrapper>
      </div>
    </div>
  );
}
const GeolocationPopupWidget = widget(
  GeolocationPopupDetail,
  WidgetDataType.SEARCH_RESULTS,
  'store'
);
export default function GeolocationPopup({ fields }: GeoLocationPopupProps) {
  return (
    <GeolocationPopupWidget
      fields={fields}
      rfkId={fields?.data?.datasource?.rfkid?.jsonValue?.value || 'rfk_Store_Locator_Geo_Location'}
    />
  );
}
