//global
import clsx from 'clsx';
import { Field, Link as JssLink } from '@sitecore-jss/sitecore-jss-nextjs';
//local
import { navigationTailwindVariant } from 'tailwindVariants/components/navigationTailwindVariant';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import TextHelper from 'src/helpers/commonComponents/TextHelper';
//lib
import { Result } from 'lib/types/header';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';

export type isOpen = {
  [key: string]: boolean;
};

type MobilesubNavProps = {
  subNav: Result;
  height: number;
  isOpen: isOpen;
  backText: Field<string>;
  toggleItem: (itemName: string | null) => void;
  setIsHamburger: (val: boolean) => void;
};
export default function MobilesubNav({
  setIsHamburger,
  subNav,
  isOpen,
  toggleItem,
  backText,
  height,
}: MobilesubNavProps): JSX.Element {
  // const hasChildren = subNav?.childrens?.results && subNav?.childrens?.results.length > 0;

  const {
    mobileNavOpen,
    flexGrid,
    ulWrapper,
    iconWrapper,
    navigationLink,
    fillBlack,
    iconXSmall,
    linkWrapper,
    justifyBetween,
    //h250,
    mobSubWrappwer,
    shopAllLink,
  } = navigationTailwindVariant();

  return (
    <div className={``} key={subNav?.name}>
      {subNav?.childrens
        ? subNav?.childrens?.results?.map((sub, key) => {
            const isHide = sub?.hideInMobile?.jsonValue?.value;
            const hasChildren = sub?.childrens?.results && sub?.childrens?.results?.length > 0;

            return !isHide ? (
              <ul
                key={key}
                className={clsx(
                  ulWrapper(),
                  `${
                    isOpen[sub?.link?.jsonValue?.value?.text || '']
                      ? `[&>*]:!translate-x-0`
                      : `${sub?.link?.jsonValue?.value?.text}`
                  }`
                )}
              >
                {hasChildren ? (
                  <div
                    className={flexGrid()}
                    onClick={() =>
                      hasChildren ? toggleItem(sub?.link?.jsonValue?.value?.text as string) : ''
                    }
                  >
                    {sub?.childrens?.results && sub?.link?.jsonValue?.value?.text && (
                      <button aria-label={'sub menu link' + key} className={navigationLink()}>
                        {sub?.link?.jsonValue?.value?.text}
                      </button>
                    )}

                    {hasChildren && (
                      <IconHelper
                        icon={'chevron-right'}
                        className={clsx(iconWrapper(), fillBlack(), iconXSmall())}
                        onClick={() => toggleItem(sub?.link?.jsonValue?.value?.text as string)}
                      />
                    )}
                  </div>
                ) : (
                  sub?.link?.jsonValue?.value?.href &&
                  sub?.link?.jsonValue?.value?.href?.length > 0 && (
                    <JssLink
                      editable={false}
                      field={sub?.link?.jsonValue}
                      onClick={() => {
                        toggleItem(''), setIsHamburger(false);
                      }}
                      className={clsx(justifyBetween(), linkWrapper(), flexGrid())}
                    />
                  )
                )}
                <div
                  style={{ height: `calc(100vh - ${height}px)` }}
                  className={clsx(
                    'overflow-y-auto pb-[170px]',
                    mobileNavOpen(),
                    `${isOpen[sub?.link?.jsonValue?.value?.text || ''] ? '!translate-x-0 ' : ''}  `
                  )}
                >
                  {sub?.childrens?.results && (
                    <div className={clsx(flexGrid(), mobSubWrappwer(), 'relative')}>
                      <div
                        className={sub?.shopall?.jsonValue?.value?.text ? 'mr-auto flex' : 'flex'}
                        onClick={() => toggleItem(sub?.link?.jsonValue?.value?.text as string)}
                      >
                        <IconHelper
                          icon={'chevron-left'}
                          className={clsx(iconWrapper(), fillBlack(), iconXSmall())}
                        />
                        <TextHelper tag="button" field={backText} />
                      </div>
                      <span
                        className={
                          sub?.shopall?.jsonValue?.value?.href
                            ? 'ml-auto mr-auto'
                            : 'absolute left-1/2 transform -translate-x-1/2 text-center'
                        }
                      >
                        {sub?.link?.jsonValue?.value?.text}
                      </span>
                      <LinkHelper
                        className={clsx(shopAllLink(), 'ml-auto')}
                        field={sub?.shopall?.jsonValue}
                        onClick={() => {
                          toggleItem(''), setIsHamburger(false);
                        }}
                      ></LinkHelper>
                    </div>
                  )}
                  {sub?.childrens?.results?.map((last, key) => {
                    const href =
                      last?.link?.jsonValue?.value?.href ||
                      last?.mobileLink?.jsonValue?.value?.href;

                    const linkField = last?.mobileLink?.jsonValue?.value?.href
                      ? last?.mobileLink?.jsonValue
                      : last?.link?.jsonValue;
                    if (href && href.length > 0 && linkField) {
                      return (
                        <LinkHelper
                          key={key}
                          editable={false}
                          field={linkField}
                          onClick={() => {
                            toggleItem(''), setIsHamburger(false);
                          }}
                          className={clsx(
                            linkWrapper(),
                            ulWrapper(),
                            `${isOpen[subNav?.name || ''] ? '[&>*div]:!translate-x-0' : ''}`
                          )}
                        />
                      );
                    } else {
                      return <></>;
                    }
                  })}
                </div>
              </ul>
            ) : (
              ''
            );
          })
        : ''}
    </div>
  );
}
