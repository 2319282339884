/* eslint-disable @typescript-eslint/no-explicit-any */
import { Item, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { useSearchResultsSelectedFilters } from '@sitecore-search/react';
import clsx from 'clsx';
import ImageHelper from 'src/helpers/commonComponents/ImageHelper';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import TextHelper from 'src/helpers/commonComponents/TextHelper';
import { PSP } from 'models/PetSuppliesPlus.Model';
import { useEffect, useState } from 'react';
import { urlToFacet } from './use-ensure-facet-url';

import intersection from 'lodash/intersection';
import { useRerenderPersistState } from 'src/helpers/utility/RerenderOnRouteChange';
import SplideSlider from 'src/helpers/commonComponents/SplideSlider';
import searchResultsTailwind from './SearchResultsTailwind';
import { getTruncatedProductPromoTag } from 'src/helpers/Constants';
import { findMatchingItem, getMatchData } from 'lib/utils/string-utils';
import { useOcSelector } from 'src/redux/ocStore';
import { productDetailTailwindVariant } from 'tailwindVariants/components/productDetailTailwindVariant';

interface VisualFiltersType {
  visualFiltersData?: any;
  keyphrase?: string;
}

export const VisualFilters = ({ visualFiltersData, keyphrase }: VisualFiltersType) => {
  const [showOfferTooltip, setShowOfferTooltip] = useState<string | false>();
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined);
  const context = useSitecoreContext();
  const route = context.sitecoreContext
    .route as PSP.Sitecore.templates.PetSuppliesPlus.PageTypes.Fields.ProductsListingPage;

  const [visualFilter, setVisualFilter] = useState(
    route?.fields?.visualFilterDatasource as
      | PSP.Sitecore.templates.PetSuppliesPlus.Search.VisualFilter.Fields.VisualFilterDefinition
      | undefined
  );

  // here will need to leverage `useVisualFilters` in loop for getting the results of the children:
  useEffect(() => {
    setVisualFilter(route?.fields?.visualFilterDatasource);
  }, []);

  useEffect(() => {
    const findMatch = async () => {
      if (keyphrase) {
        const matchedItem = await findMatchingItem(
          keyphrase,
          visualFiltersData?.children?.results || [],
          getMatchData
        );
        if (matchedItem) {
          setVisualFilter(matchedItem?.visualFilterDatasource?.jsonValue);
        }
      }
    };
    findMatch();
  }, [keyphrase]);

  // Will require final data just like below:
  const filters = visualFilter?.fields?.filters as
    | (Item & PSP.Sitecore.templates.PetSuppliesPlus.Search.VisualFilter.Fields.VisualFilterItem)[]
    | undefined;

  useEffect(() => {
    if (selectedIndex == undefined) {
      const hasWindow = typeof window !== 'undefined';
      const hash = hasWindow ? window.location.hash.replace(/^#+/, '') : '';
      if (hash) {
        const decodedHashVal = decodeURIComponent(hash);
        const matchingIndex = filters?.findIndex((facet) => {
          const visualFilterFacet = facet?.fields?.visualFilterFacet as
            | PSP.Sitecore.templates.PetSuppliesPlus.Search.Facets.Fields.FacetItem
            | undefined;
          const anchor = facet?.fields?.link?.value?.anchor;
          const facetName = visualFilterFacet?.fields?.facetName?.value;
          const facetValue = facet?.fields?.visualFilterFacetValue?.value;
          const actualFacetName = `f-${facetName}=${facetValue?.trim()?.replace(' ', '+')}`;
          return anchor == decodedHashVal || actualFacetName == decodedHashVal;
        });
        setSelectedIndex(matchingIndex);
      } else {
        setSelectedIndex(-1);
      }
    }
  }, [selectedIndex, filters]);

  const [selectedAnchor, setSelectedAnchor] = useRerenderPersistState<string>('VisualFilters');

  const selectedFacetsFromApi = useSearchResultsSelectedFilters();
  const normalizedSelectedFacets = selectedFacetsFromApi
    .map((x) => `${x?.facetId}-${x?.valueLabel}`)
    .join('|');

  useEffect(() => {
    const anchorFacets = urlToFacet(selectedAnchor);
    const normalizedAnchorFacets = anchorFacets.map((x) => `${x.facetId}-${x.facetValueText}`);
    const normalizedSelectedFacetsArray = normalizedSelectedFacets.split('|');
    const sharedFacets = intersection(normalizedSelectedFacetsArray, normalizedAnchorFacets);
    if (!sharedFacets.length) {
      setSelectedAnchor('');
    }
  }, [selectedAnchor, normalizedSelectedFacets, setSelectedAnchor]);

  const {
    splideWrapper,
    splideLabel,
    splideImageWrapper,
    splideImage,
    visualPrevArrow,
    visualNextArrow,
  } = searchResultsTailwind({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const {
    topTooltipWrapper,
    tooltipContainer,
    topToolTipDescription,
    topTooltipArrowFirstDiv,
    topTooltipArrowSecondDiv,
  } = productDetailTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const maxLengthForPromoTag =
    Number(useOcSelector((state) => state?.otherSettingSlice?.data?.maxLengthForPromoTag?.value)) ||
    0;
  if (!filters) {
    return <></>;
  }

  return (
    <div className={splideWrapper({ className: 'max-w-fit visualFilters' })}>
      {selectedIndex !== undefined && (
        <SplideSlider
          onReady={(splide) => {
            selectedIndex && splide?.go(selectedIndex);
          }}
          options={{
            pagination: false,
            perMove: 1,
            gap: 0,
            perPage: filters.length < 7 ? filters.length : 7,
            breakpoints: {
              1279: {
                perPage: 7,
              },
              1024: {
                perPage: 5,
              },
              767: {
                perPage: 5,
                type: 'loop',
              },
              640: {
                perPage: 3,
                type: 'loop',
              },
            },
            classes: {
              prev: visualPrevArrow(),
              next: visualNextArrow(),
            },
          }}
        >
          {filters.map((x, index) => {
            const visualFilterFacet = x?.fields?.visualFilterFacet as
              | PSP.Sitecore.templates.PetSuppliesPlus.Search.Facets.Fields.FacetItem
              | undefined;
            const facetName = visualFilterFacet?.fields?.facetName?.value;
            // PSP-2326: Converts space to '+' and '&' to '%26' with some basic URI encoding.
            const facetValue = encodeURIComponent(
              x?.fields?.visualFilterFacetValue?.value || ''
            ).replace(/%20/g, '+'); // Cages+%26+Habitats
            const hasFacet = facetName && facetValue;
            const anchor = hasFacet
              ? `f-${facetName}=${facetValue}`
              : x?.fields?.link?.value.anchor ?? '';
            const link = hasFacet
              ? {
                  value: { href: '#' + anchor, anchor: anchor, linktype: 'anchor' },
                }
              : x?.fields?.link ?? {
                  value: {
                    href: '',
                  },
                };
            return (
              <div
                className={clsx('!border-0', {
                  '!border-[2px] border-color-brand-primary-1-base max-w-[136px] w-full h-full':
                    index === selectedIndex,
                })}
                key={x.id}
              >
                {showOfferTooltip === x?.id && (
                  <div
                    className={topTooltipWrapper({
                      className:
                        '!bottom-[90%] lg:!bottom-[90%] !left-[50%] -translate-x-1/2 lg:!left-[50%] !w-fit',
                    })}
                  >
                    <div className={tooltipContainer()}>
                      <div className={topTooltipArrowFirstDiv()}></div>
                      <div className={topTooltipArrowSecondDiv()}></div>
                      <p className={topToolTipDescription({ className: 'text-center' })}>
                        {x?.fields?.filterDescriptions?.value}
                      </p>
                    </div>
                  </div>
                )}
                <LinkHelper
                  field={link}
                  isVisualFilter={true}
                  onClick={() => {
                    // We need a setTImeout to wait until after navigation happens
                    // Otherwise it would get cleared out by the useEffect above
                    setTimeout(() => {
                      setSelectedAnchor(anchor);
                    }, 100);
                  }}
                >
                  <div
                    className={splideImageWrapper({
                      className: 'flex justify-center',
                    })}
                  >
                    <ImageHelper field={x?.fields?.filterImage} className={splideImage()} />
                  </div>

                  <div
                    className={splideLabel()}
                    onMouseEnter={() => {
                      if (
                        x?.id &&
                        x?.fields?.filterDescriptions?.value &&
                        maxLengthForPromoTag &&
                        x?.fields?.filterDescriptions?.value?.length > maxLengthForPromoTag
                      ) {
                        setShowOfferTooltip(x?.id);
                      }
                    }}
                    onMouseLeave={() => {
                      if (
                        x?.id &&
                        x?.fields?.filterDescriptions?.value &&
                        maxLengthForPromoTag &&
                        x?.fields?.filterDescriptions?.value?.length > maxLengthForPromoTag
                      ) {
                        setShowOfferTooltip(false);
                      }
                    }}
                  >
                    <TextHelper
                      field={{
                        value:
                          getTruncatedProductPromoTag(
                            x?.fields?.filterDescriptions?.value || '',
                            maxLengthForPromoTag
                          ) || '',
                      }}
                    />
                  </div>
                </LinkHelper>
              </div>
            );
          })}
        </SplideSlider>
      )}
    </div>
  );
};
