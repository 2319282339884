import { getProductDetailPath } from 'src/helpers/Constants';
import { PspProductRootProps } from '../PspProductRoot';
import Link from 'next/link';
import { ProductSearchResultModelWithVariants } from '../types';
import { BuyerProductWithXp } from 'src/redux/xp';

export const ProductLink = ({
  actions,
  product,
  index,
  children,
  styles,
}: React.PropsWithChildren<
  PspProductRootProps & { product: BuyerProductWithXp | ProductSearchResultModelWithVariants }
>) => {
  return (
    <Link
      prefetch={false}
      aria-label="productLink"
      href={getProductDetailPath({
        ...product,
        ParentID: (product as BuyerProductWithXp)?.ParentID || product?.parentid,
      })}
      onClick={() => {
        actions.onItemClick({
          id: product.id ?? '',
          index,
          sourceId: product.source_id,
        });
      }}
      className={styles}
    >
      {children}
    </Link>
  );
};
